declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Layout from 'components/layout'
import MarketoForm from 'components/modal-manager/forms/components/marketo-form'
import { Heading, A } from 'components/modal-manager/forms/components/shared'
import { tablet } from 'shared/media-queries'
import styled from 'styled-components'
import BBHeader from 'components/modal-manager/forms/components/bb-header'
import {
	MarketoUrl,
	MarketoCode,
} from 'components/modal-manager/forms/constants'

const Container = styled.div`
	max-width: 300px;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	padding-top: 50px;
	${tablet} {
		max-width: 100%;
	}
`

const RequestADemo: React.FC = () => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1844)
		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			form.onSuccess(() => {
				updateSubmitStatus(true)
				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [])

	return (
		<Layout title="Request a Demo" hideNav hideFooter>
			<Container>
				{!submitted && (
					<>
						<BBHeader />
						<MarketoForm id="mktoForm_1844" />
					</>
				)}
				{submitted && (
					<div id="request-a-demo-success-modal">
						<Heading>Thank you for your interest in BuildingConnected!</Heading>
						<p>
							One of our representatives will reach out to you shortly. If you
							already have an account, you can{' '}
							<A href="https://app.buildingconnected.com">sign in here</A>.
						</p>
						<p>
							If you have any questions about responding to bid invites, please
							feel free to contact{' '}
							<A href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true">
								support
							</A>
							.
						</p>
					</div>
				)}
			</Container>
		</Layout>
	)
}

export default RequestADemo
